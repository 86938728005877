const players = document.querySelectorAll('.js-video');

if (players[0]) {
  const createPlayer = (evt) => {
    const trigger = evt.target;
    const parent = trigger.closest('div');
    const iframe = document.createElement('iframe');
    iframe.width = '100%;';
    iframe.height = '100%';
    iframe.allow =
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;';
    iframe.allowfullscreen = '';
    iframe.src = trigger.href;
    iframe.setAttribute('frameborder', 0);
    trigger.remove();
    parent.append(iframe);
  };

  Array.from(players).forEach((player) => {
    player.addEventListener(
      'click',
      (evt) => {
        evt.preventDefault();
        createPlayer(evt);
      },
      { once: true }
    );
  });
}
