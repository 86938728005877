const triggerElements = document.querySelectorAll('.js-dropdown');

if (triggerElements[0]) {
  triggerElements.forEach((item) => {
    item.addEventListener('click', (evt) => {
      if (evt.target.tagName !== 'BUTTON') return;
      if (item.querySelector('.side-item__list')) {
        const container = item.querySelector('.side-item__list');
        const height = container.scrollHeight;

        if (item.classList.contains('is-open')) {
          item.classList.remove('is-open');
          container.style.maxHeight = '0px';
        } else {
          container.style.maxHeight = `${height}px`;
          item.classList.toggle('is-open');
        }
      }
    });
  });
}
