import createSlider from '../../js/partials/slider';
// import { media } from '../../js/partials/const';

const reviewSlidersEl = document.querySelector('.js-review-slider');

if (reviewSlidersEl) {
  createSlider(reviewSlidersEl, {
    spaceBetween: 30,
    slidesPerView: 'auto',
    grabCursor: true,
    loop: true,
    // breakpoints: {
    //   [media.mSize]: {
    //     slidesPerView: 1,
    //     spaceBetween: 32,
    //   },
    //   [media.lSize]: {
    //     slidesPerView: 1.1,
    //     spaceBetween: 80,
    //   },
    //   [media.xlSize]: {
    //     slidesPerView: 1.1,
    //     spaceBetween: 160,
    //   },
    // },
    // grabCursor: true,
  });
}
