import Tabsmenu from './tabs';
import { maskPhone } from './mask';
import { validateForms } from './validation';
import { checkVisible } from './utils';

document.addEventListener('DOMContentLoaded', () => {
  maskPhone('input[type="tel"]');
  validateForms();

  const tabsmenu = document.querySelectorAll('.tabs');
  if (tabsmenu) {
    tabsmenu.forEach((menu) => new Tabsmenu(menu));
  }

  // const anchor = window.location.hash;

  // if (anchor) {
  //   const target = document.getElementById(anchor.substring(1));
  //   if (target) {
  //     target.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'nearest',
  //     });
  //   }
  // }

  const videoPlayTriggers = document.querySelectorAll('.js-play-video');

  const togglePlay = (video, parent) => {
    if (video.paused || video.ended) {
      video.play();
      parent.classList.add('play');
    } else {
      video.pause();
      parent.classList.remove('play');
    }
  };

  if (videoPlayTriggers[0]) {
    videoPlayTriggers.forEach((item) => {
      const video = item.previousElementSibling;
      const parent = item.parentElement;

      video.addEventListener('play', () => {
        parent.classList.add('play');
      });
      video.addEventListener('pause', () => {
        parent.classList.remove('play');
      });

      item.addEventListener('click', () => {
        togglePlay(video, parent);
      });
    });
  }

  window.addEventListener('hashchange', () => {
    const anchor = window.location.hash;
    if (anchor) {
      const target = document.getElementById(anchor.substring(1));
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  });
});
