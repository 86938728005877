export const speed = 1000;
export const media = {
  sSize: 620,
  mSize: 768,
  lSize: 1240,
  xlSize: 1920,
};
export const isMobile = window.matchMedia(
  `(max-width: ${media.mSize - 1}px)`
).matches;
export const isTablet = window.matchMedia(
  `(min-width: ${media.mSize}px)`
).matches;
export const isDesktop = window.matchMedia(
  `(min-width: ${media.lSize}px)`
).matches;
