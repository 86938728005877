import createSlider from '../../js/partials/slider';
import { media } from '../../js/partials/const';

const relatedSlidersEl = document.querySelector('.js-related-slider');

if (relatedSlidersEl) {
  createSlider(relatedSlidersEl, {
    modules: [],
    spaceBetween: 10,
    slidesPerView: 1.1,
    loop: true,
    grabCursor: true,
    breakpoints: {
      [media.mSize]: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      [media.lSize]: {
        spaceBetween: 16,
        slidesPerView: 4,
      },
    },
  });
}
