window.addEventListener('scroll', () => {
  const header = document.querySelector('.page-header');

  if (header) {
    if (window.pageYOffset > 200) {
      if (header.classList.contains('fixed')) return;
      header.classList.add('fixed');
    } else {
      header.classList.remove('fixed');
    }
  }
});
