import { Navigation, Pagination } from 'swiper';
import { media } from '../../js/partials/const';
import createSlider from '../../js/partials/slider';

const previewSliderEls = document.querySelectorAll('.js-preview-slider');

if (previewSliderEls[0]) {
  previewSliderEls.forEach((slider, i) => {
    createSlider(slider, {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: `.swiper-preview-button-next${i}`,
        prevEl: `.swiper-preview-button-prev${i}`,
      },

      spaceBetween: 10,
      slidesPerView: 1.2,
      centeredSlides: true,
      breakpoints: {
        [media.mSize]: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        [media.lSize]: {
          slidesPerView: 4,
          spaceBetween: 30,
          centeredSlides: false,
        },
      },
      loop: true,
      grabCursor: true,
    });
  });
}
