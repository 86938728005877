import { switchActiveClass } from './utils';

export default class {
  constructor(tabmenu) {
    this.tabmenu = tabmenu;
    this.blocks = this.tabmenu.nextElementSibling.querySelectorAll(
      '.js-tabs-content > *'
    );
    this.tabs = this.tabmenu.querySelectorAll('.tabs__btn');
    this.tabmenu.addEventListener('click', this.switсhTab.bind(this));
  }

  switсhTab(evt) {
    if (evt.target.tagName !== 'BUTTON') return;
    const i = Array.from(this.tabs).indexOf(evt.target);

    switchActiveClass(this.tabs, i);
    this.switchBloks(i);
  }

  switchBloks(i) {
    switchActiveClass(this.blocks, i);

    this.blocks[i].classList.add('active');
  }
}
