const mapEl = document.querySelectorAll('#map');

if (mapEl[0]) {
  /* eslint-disable */
  ymaps.ready(init);
  /* eslint-enable */
  const mapLat = mapEl[0].getAttribute('data-lat');
  const mapLng = mapEl[0].getAttribute('data-lng');
  const mapZoom = mapEl[0].getAttribute('data-zoom');
  const mapIcon = mapEl[0].getAttribute('data-icon');

  // eslint-disable-next-line no-inner-declarations
  function init() {
    /* eslint-disable */
    const map = new ymaps.Map('map', {
      center: [mapLat, mapLng],
      zoom: mapZoom,
      controls: [],
    });
    /* eslint-enable */

    map.behaviors.disable('scrollZoom');

    map.controls.add('zoomControl', {
      position: {
        top: 15,
        left: 15,
      },
    });

    /* eslint-disable */
    const iconPlace = new ymaps.Placemark(
      /* eslint-enable */
      [mapLat, mapLng],
      {},
      {
        iconImageSize: [56, 67],
        iconLayout: 'default#image',
        iconImageHref: mapIcon,
        iconImageOffset: [-28, -33],
      }
    );

    map.geoObjects.add(iconPlace);
  }
}
