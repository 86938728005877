import '../components/block-slider/block-slider';
import '../components/intro/intro';
import '../components/map/map';
import '../components/menu-btn/menu-btn';
import '../components/offer/offer';
import '../components/page-header/page-header';
import '../components/preview/preview';
import '../components/quiz/quiz';
import '../components/related/related';
import '../components/review/review';
import '../components/side/side';
import '../components/side-item/side-item';
import '../components/text-slider/text-slider';
import '../components/video-block/video-block';
import './partials/script';
