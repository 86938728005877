import { toggleClass, removeClass } from '../../js/partials/utils';

const btn = document.querySelector('.side-menu-btn');
const menu = document.querySelector('.side');

const closeMenu = () => {
  removeClass(btn, 'side--opened');
  removeClass(menu, 'side--opened');
  removeClass(document.body, 'modal-open');
  btn.setAttribute('aria-expanded', false);
};

const onEscPress = (evt) => {
  if (evt.code === 'Escape') {
    closeMenu();
    document.removeEventListener('keydown', onEscPress);
  }
};

if (btn) {
  const onMenuBtnClick = () => {
    toggleClass(btn, 'side--opened');
    toggleClass(menu, 'side--opened');
    toggleClass(document.body, 'modal-open');
    btn.setAttribute(
      'aria-expanded',
      `${!(btn.getAttribute('aria-expanded') === 'true')}`
    );
    document.addEventListener('keydown', onEscPress);
  };

  menu.querySelector('.btn--close').addEventListener('click', () => {
    console.log('click');
    closeMenu();
  });

  console.log(menu.querySelector('.btn--close'));

  btn.addEventListener('click', onMenuBtnClick);
}
