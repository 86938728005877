export const createElement = (template) => {
  const newElement = document.createElement(`div`);
  newElement.innerHTML = template;
  return newElement.firstChild;
};

export const addClass = (el, className) => {
  el.classList.add(className);
};

export const removeClass = (el, className) => {
  el.classList.remove(className);
};

export const toggleClass = (el, className) => {
  el.classList.toggle(className);
};

export const removeElement = (el) => {
  el.parentElement.removeChild(el);
};

export const serialize = (formData) =>
  [...formData].reduce((acc, [key, value]) => {
    if (acc.length > 0) {
      // eslint-disable-next-line no-param-reassign
      acc += '&';
    }
    // eslint-disable-next-line no-param-reassign
    acc += encodeURI(`${key}=${value}`);

    return acc;
  }, '');

export const checkVisible = (animatedElement, className, options) => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    const IO = new IntersectionObserver(
      // eslint-disable-next-line
      function (entries, IO) {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            // eslint-disable-next-line
            return;
            // eslint-disable-next-line
          } else {
            entry.target.classList.add(className);
            // document.body.classList.add('modal-open');

            // setTimeout(() => {
            //   document.body.classList.remove('modal-open');
            // }, 100);

            console.log(entry.target);

            // IO.unobserve(entry.target);
          }
        });
      },
      options
    );

    animatedElement.forEach((text) => {
      IO.observe(text);
    });
  }
};

export const switchActiveClass = (parentEl, active, className = 'active') => {
  const currentActiveEl = parentEl.querySelector(className);
  if (currentActiveEl) removeClass(currentActiveEl, className);

  if (typeof active === 'number') {
    addClass(parentEl[active], className);
  } else {
    addClass(parentEl.querySelector(active), className);
  }
};

export const throttle = (delay, fn) => {
  let last;
  let deferTimer;
  return (...args) => {
    const context = this;
    const now = +new Date();
    if (last && now < last + delay) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, delay);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};
