import { toggleClass, removeClass } from '../../js/partials/utils';

const btn = document.querySelector('.menu-btn');
const menu = document.querySelector('.main-menu');

const onEscPress = (evt) => {
  if (evt.code === 'Escape') {
    removeClass(btn, 'menu-btn--opened');
    removeClass(menu, 'main-menu--opened');
    removeClass(document.body, 'modal-open');
    btn.setAttribute('aria-expanded', false);
    document.removeEventListener('keydown', onEscPress);
  }
};

if (btn) {
  const onMenuBtnClick = () => {
    toggleClass(btn, 'menu-btn--opened');
    toggleClass(menu, 'main-menu--opened');
    toggleClass(document.body, 'modal-open');
    btn.setAttribute(
      'aria-expanded',
      `${!(btn.getAttribute('aria-expanded') === 'true')}`
    );
    document.addEventListener('keydown', onEscPress);
  };

  btn.addEventListener('click', onMenuBtnClick);
}
