import { Navigation, Scrollbar, Thumbs } from 'swiper';
import createSlider from '../../js/partials/slider';
import { media } from '../../js/partials/const';

const blockSlidersEl = document.querySelector('.js-block-slider');
const blockThumbSlidersEl = document.querySelector('.js-block-thumb-slider');

if (blockSlidersEl && blockThumbSlidersEl) {
  const blockThumbSlider = createSlider(blockThumbSlidersEl, {
    modules: [Navigation],
    spaceBetween: 20,
    slidesPerView: 3,
    freeMode: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-block-button-next',
      prevEl: '.swiper-block-button-prev',
    },
    grabCursor: true,
    breakpoints: {
      [media.mSize]: {
        slidesPerView: 4,
      },
      [media.lSize]: {
        slidesPerView: 5,
      },
    },
  });

  createSlider(blockSlidersEl, {
    modules: [Navigation, Thumbs, Scrollbar],
    thumbs: {
      swiper: blockThumbSlider,
    },
    navigation: {
      nextEl: '.swiper-block-button-next',
      prevEl: '.swiper-block-button-prev',
    },
    scrollbar: {
      el: '.block-slider__scroll',
      draggable: true,
    },
    spaceBetween: 0,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
  });
}
