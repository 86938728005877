import { Navigation, Pagination, Autoplay } from 'swiper';
import createSlider from '../../js/partials/slider';

const introSlidersEl = document.querySelector('.js-intro-slider');

if (introSlidersEl) {
  createSlider(introSlidersEl, {
    modules: [Navigation, Pagination, Autoplay],
    navigation: {
      nextEl: '.swiper-intro-button-next',
      prevEl: '.swiper-intro-button-prev',
    },
    autoplay: {
      delay: 7000,
    },
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    grabCursor: true,
    pagination: {
      el: '.intro__pagination',
      clickable: true,
    },
  });
}
