const Method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const headers = { 'X-Requested-With': 'XMLHttpRequest' };
// const parser = new DOMParser();

const checkStatus = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const getStatusTemplate = (response) => {
  const template = document.querySelector('#status-template');
  let clone;

  if (template && response.success) {
    clone = template.content.cloneNode(true);
    const title = clone.querySelector('.request__title');
    const text = clone.querySelector('.request__text');

    if (title) {
      if (response.title) {
        title.textContent = response.title;
      } else {
        title.remove();
      }
    }
    if (text) {
      if (response.text) {
        text.textContent = response.text;
      } else {
        text.remove();
      }
    }
  }
  return clone;
};

const extractIn = (response, format = 'json') => {
  const formats = {
    json: () => response.json(),
    text: () => response.text(),
    formData: () => response.formData(),
    blob: () => response.blob(),
  };

  if (format in formats) {
    return formats[format]();
  }

  return console.error('there is no such format'); //eslint-disable-line
};

const showStatus = (status, form) => {
  const template = getStatusTemplate(status);

  const parentContainer = form.closest(`.js-request-container`);

  parentContainer.classList.add('show-result');

  // document.querySelector('section.request').append(template);
  parentContainer.append(template);
  // document.body.appendChild(template);
};

const request = async (
  { url, body = null, method = Method.GET },
  format,
  // eslint-disable-next-line default-param-last
  cb = showStatus,
  form
) => {
  try {
    const response = await fetch(url, {
      method,
      body,
      headers,
    });
    const status = await checkStatus(response);
    const data = await extractIn(status, format);

    if (cb) {
      return cb(data, form);
    }
    return data;
  } catch (err) {
    showStatus(err);
    return console.error(err); // eslint-disable-line
  }
};

export default {
  load(url, form, format, cb) {
    return request({ url }, format, cb, form);
  },

  upload(url, body, form, boolean, format, callback) {
    if (!boolean) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return request(
      {
        url,
        method: Method.POST,
        body,
        headers,
      },
      format,
      callback,
      form
    );
  },
};
