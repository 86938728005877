import { Navigation, Pagination } from 'swiper';
import createSlider from '../../js/partials/slider';

const textSlidersEl = document.querySelectorAll('.js-text-slider');

if (textSlidersEl[0]) {
  textSlidersEl.forEach((slider) => {
    createSlider(slider, {
      modules: [Navigation, Pagination],

      navigation: {
        nextEl: '.swiper-text-button-next',
        prevEl: '.swiper-text-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      spaceBetween: 0,
      slidesPerView: 1,
      grabCursor: true,
      loop: true,
      // breakpoints: {
      //   [media.mSize]: {
      //     slidesPerView: 1,
      //     spaceBetween: 32,
      //   },
      //   [media.lSize]: {
      //     slidesPerView: 1.1,
      //     spaceBetween: 80,
      //   },
      //   [media.xlSize]: {
      //     slidesPerView: 1.1,
      //     spaceBetween: 160,
      //   },
      // },
    });
  });
}
