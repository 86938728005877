import { Navigation, Pagination, EffectFade } from 'swiper';
import createSlider from '../../js/partials/slider';

const quizSlidersEl = document.querySelector('.js-quiz-slider');
const paginElems = document.querySelector('.js-quiz-pag-slider');
const btnNext = document.querySelector('.swiper-quiz-button-next');
const btnSubmit = document.querySelector('.form__submit');

if (quizSlidersEl) {
  const paginArray = [];
  quizSlidersEl
    .querySelectorAll('.js-quiz-slider .swiper-slide')
    .forEach((item, i) => {
      paginArray.push(i + 1);
    });

  const quizSlider = createSlider(quizSlidersEl, {
    modules: [Navigation, Pagination, EffectFade],
    navigation: {
      nextEl: '.swiper-quiz-button-next',
      prevEl: '.swiper-quiz-button-prev',
    },
    allowTouchMove: false,
    spaceBetween: 0,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    grabCursor: false,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: false,
      // eslint-disable-next-line object-shorthand
      renderBullet: function (index, className) {
        return `<span data-index=${paginArray[index]} class=${className}> ${paginArray[index]}</span>`;
      },
    },
  });

  if (paginElems) {
    quizSlider.on('slideChange', () => {
      paginElems
        .querySelectorAll('.swiper-pagination-bullet')
        ?.forEach((bullet) => {
          if (quizSlider.realIndex + 1 > +bullet.dataset.index) {
            bullet.classList.add('fill');
          } else {
            bullet.classList.remove('fill');
          }

          if (
            // eslint-disable-next-line eqeqeq
            quizSlider.realIndex + 1 ==
            paginElems.querySelectorAll('.swiper-pagination-bullet').length
          ) {
            btnNext.classList.add('hide');
            btnSubmit.classList.remove('hide');
          } else {
            btnNext.classList.remove('hide');
            btnSubmit.classList.add('hide');
          }
        });
    });
  }
}

if (window.matchMedia('(min-width: 1240px)').matches) {
  (function () {
    const items = document.querySelectorAll('.js-categories-item');

    items.forEach((item, i) => {
      item.querySelectorAll('.js-img').forEach((img, n) => {
        // eslint-disable-next-line no-undef
        const controller = new ScrollMagic.Controller();

        // eslint-disable-next-line no-undef
        const tween = TweenMax.to(img, 0.5, {
          y: '-100vh',
        });

        console.log(item.querySelectorAll('.js-img').length);

        // eslint-disable-next-line no-undef, no-unused-vars
        const scene = new ScrollMagic.Scene({
          triggerElement: items[i],
          duration:
            window.screen.height *
            2 *
            (item.querySelectorAll('.js-img').length - n),
          // duration: $(window).height() * 3,
        })
          .setTween(tween)
          .addTo(controller);
      });
    });
  })();
}
