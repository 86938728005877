import { Navigation, Thumbs, FreeMode } from 'swiper';
import createSlider from '../../js/partials/slider';
import { media } from '../../js/partials/const';

const offerThumbSlidersEl = document.querySelector('.js-offer-thumb-slider');
const offerSlidersEl = document.querySelector('.js-offer-slider');

if (offerThumbSlidersEl && offerSlidersEl) {
  const offerThumbSlider = createSlider(offerThumbSlidersEl, {
    modules: [Navigation, FreeMode],
    spaceBetween: 10,
    slidesPerView: 'auto',

    loop: true,
    direction: 'horizontal',
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-offer-button-next',
      prevEl: '.swiper-offer-button-prev',
    },
    grabCursor: true,
    breakpoints: {
      [media.mSize]: {
        slidesPerView: 'auto',
      },
      [media.lSize]: {
        slidesPerView: 4,
        direction: 'vertical',
      },
    },
  });

  createSlider(offerSlidersEl, {
    modules: [Navigation, Thumbs],
    thumbs: {
      swiper: offerThumbSlider,
    },
    navigation: {
      nextEl: '.swiper-offer-button-next',
      prevEl: '.swiper-offer-button-prev',
    },
    spaceBetween: 0,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    // breakpoints: {
    //   [media.mSize]: {
    //     slidesPerView: 1,
    //     spaceBetween: 32,
    //   },
    //   [media.lSize]: {
    //     slidesPerView: 1.1,
    //     spaceBetween: 80,
    //   },
    //   [media.xlSize]: {
    //     slidesPerView: 1.1,
    //     spaceBetween: 160,
    //   },
    // },
  });
}
