import api from './api';
import { serialize } from './utils';

// const TIMEOUT = 3000;

export default class Validation {
  static regExps = {
    'user-name': /^[а-яёА-ЯЁ\s]+$/,
    'user-email':
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    'user-tel': /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
  };

  static messages = [
    'Незаполненное поле ввода',
    'Введите Ваше реальное имя',
    'Укажите Вашу электронную почту',
    'Неверный формат электронной почты',
    'Укажите ваш номер телефона',
    'Укажите номер в формате +7 (XXX) XXX XX XX',
  ];

  static getError(formData, property) {
    let error = '';

    const validate = {
      'user-name': () => {
        if (
          formData.get('user-name').length === 0 ||
          Validation.regExps['user-name'].test(formData.get('user-name')) ===
            false
        ) {
          error = Validation.messages[1]; // eslint-disable-line
        }
      },
      'user-email': () => {
        if (formData.get('user-email').length === 0) {
          error = Validation.messages[2]; // eslint-disable-line
        } else if (
          Validation.regExps['user-email'].test(formData.get('user-email')) ===
          false
        ) {
          error = Validation.messages[3]; // eslint-disable-line
        }
      },
      'user-tel': () => {
        if (formData.get('user-tel').length === 0) {
          error = Validation.messages[4]; // eslint-disable-line
        } else if (
          Validation.regExps['user-tel'].test(formData.get('user-tel')) ===
          false
        ) {
          error = Validation.messages[5]; // eslint-disable-line
        }
      },
    };

    if (property in validate) {
      validate[property]();
    }
    return error;
  }

  constructor(form) {
    this.form = form;
    this.fields = this.form.querySelectorAll('.form__field input');
    this.btn = this.form.querySelector('[type=submit]');
    // this.isError = false;
    this.validForm = this.validForm.bind(this);
    // this.validBlurField = this.validBlurField.bind(this);
    this.registerEventsHandler();
  }

  registerEventsHandler() {
    this.btn.addEventListener('click', this.validForm);
    // this.form.addEventListener(
    //   'focus',
    //   () => {
    //     const el = document.activeElement;
    //     if (el === this.btn) return;
    //     this.cleanError(el);
    //   },
    //   true
    // );

    // [...this.fields].forEach((field) =>
    //   field.addEventListener('blur', this.validBlurField)
    // );
  }

  validForm(evt) {
    evt.preventDefault();

    // if (this.isError) return;

    const formData = new FormData(this.form);

    // let error;
    /* eslint-disable */
    // for (const property of formData.keys()) {
    //   error = this.constructor.getError(formData, property);
    //   if (error.length == 0) continue;
    //   this.isError = true;
    //   this.showError(property, error);
    // }
    /* eslint-enable */

    // if (this.isError) return;

    // const activeModal = this.btn.closest('.modal.is-open');
    // if (activeModal) {
    //   activeModal.classList.remove('is-open');
    //   document.body.classList.remove('modal-open');
    // }

    this.btn.closest('.form').reset();

    this.sendFormData(formData);
  }

  sendFormData(formData) {
    const body = serialize(formData);

    api.load(this.form.action, this.form);
    // api.upload(this.form.action, body, this.form);
  }

  // validBlurField(evt) {
  //   const { target } = evt;
  //   const property = target.getAttribute('name');
  //   const { value } = target;

  //   const formData = new FormData();
  //   formData.append(property, value);

  // const error = this.constructor.getError(formData, property);
  // if (error.length === 0) return;

  // this.showError(property, error);
  // }

  // showError(property, error) {
  //   const el = this.form.querySelector(`[name=${property}]`);

  //   const errorElem = document.createElement('span');

  //   errorElem.classList.add('form__field-error');
  //   errorElem.textContent = error;
  //   el.insertAdjacentElement('afterend', errorElem);
  //   this.btn.disabled = true;

  //   // setTimeout(()=> {
  //   //   errorElem.remove();
  //   //   this.isError = false;
  //   //   this.btn.disabled = false;
  //   // }, TIMEOUT);
  // }

  // cleanError(el) {
  //   const errorElem = el
  //     .closest('.form__field')
  //     .querySelector('.form__field-error');
  //   if (errorElem) errorElem.remove();
  //   this.isError = false;
  //   this.btn.disabled = false;
  // }
}

export const validateForms = (context = document) => {
  const forms = context.querySelectorAll('.form');
  if (forms[0]) {
    [...forms].forEach((form) => new Validation(form));
  }
};
